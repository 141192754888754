import React from "react"

import Layout from "../components/Layouts/Base"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="404: Not Found" />

        <header className="py-12 md:pb-16 md:pt-32 max-w-2xl mx-auto">
          <h2 className="text-3xl md:text-5xl text-center font-semibold leading-snug mb-4">
            404
          </h2>
          <h3 className="text-xl md:text-2xl text-center font-semibold leading-snug">
            The page you were looking for does not exist... <br />
            the sadness.{" "}
            <span role="img" aria-label="sad">
              😢
            </span>
          </h3>
        </header>
      </Layout>
    )
  }
}

export default NotFoundPage
